import { useMemo, useCallback } from 'react';

import { getStorage } from '@boyuai/storage';

const AWS_CDN = 'awsvideo.boyuai.com';
const ALI_CDN = 'ali.videocdn.boyuai.com';
const CDN_KEY = 'video-cdn';

const defaultGetCDN = (id) => {
  return getStorage(id).get(CDN_KEY) || ALI_CDN;
};

const defaultSetCDN = (id, domain) => {
  getStorage(id).set(CDN_KEY, domain);
};

export default function usePlayerCDN(
  videos,
  id,
  getCDN = defaultGetCDN,
  setCDN = defaultSetCDN
) {
  const currentCDN = getCDN(id);
  const source = useMemo(() => {
    const data = {};
    const normalizeUrl = (url) => {
      return url.replace(AWS_CDN, ALI_CDN);
    };
    videos.forEach((item) => {
      if (item.type === 'hd') {
        data.HD = normalizeUrl(item.url);
      } else if (item.type === 'sd') {
        data.SD = normalizeUrl(item.url);
      } else if (item.type === 'ld') {
        data.LD = normalizeUrl(item.url);
      }
    });
    return JSON.stringify(data);
  }, [videos, currentCDN]);

  const onGetInstance = useCallback(
    (player) => {
      let messageMounted = false;
      player.on('error', (e) => {
        if (messageMounted) {
          return;
        }
        let memoryDomString = `
                <div class="fe-player-tooltip-wrap" style="z-index: 100;">
                    <div class="tooltip">
                        <span>播放失败</span>
                        <span class="action change-cdn">点击切换线路</span>
                        <span>或联系客服</span>
                    </div>
                </div>`;
        const ele = document.createElement('div');
        ele.innerHTML = memoryDomString;
        player._el.appendChild(ele);

        player._el.querySelector('.change-cdn').onclick = () => {
          let newCDN = ALI_CDN;
          setCDN(id, newCDN);
          player._el.querySelector('.prism-button-refresh').click();
        };
        messageMounted = true;
      });
    },
    [setCDN, id, currentCDN]
  );

  return [source, onGetInstance];
}
